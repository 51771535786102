// App Color Code : #FDCE48
import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import axios from "axios";
import VueElementLoading from "vue-element-loading";
import ServerError from "./components/Common/500";
import ImageViewer from "./components/Common/imageViewer";
import { VueEditor } from "vue2-editor";
import OtpInput from "@bachdgvn/vue-otp-input";

Vue.component("v-otp-input", OtpInput);

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
Vue.use(Viewer)
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
Vue.component(VueCropper);
Vue.component("VueEditor", VueEditor);
Vue.component("VueElementLoading", VueElementLoading);
Vue.component("ServerError", ServerError);
Vue.component("ImageViewer", ImageViewer);
Vue.config.productionTip = false;

window.axios = require("axios");

//test
// axios.defaults.baseURL = 'http://192.168.54.219:3300';
// Vue.prototype.baseURL = "http://192.168.54.219:3300" 
// Vue.prototype.mediaURL = "http://192.168.50.113:3300/wp/"

//production
axios.defaults.baseURL = "https://snakekitapi.indiansnakes.org";
Vue.prototype.baseURL = "https://snakekitapi.indiansnakes.org"; 
Vue.prototype.mediaURL = "https://snakekitapi.indiansnakes.org/wp/";
 

 
new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
