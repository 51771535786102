import Vue from "vue";
import Router from "vue-router";
import store from "./store";
import axios from "axios";

Vue.use(Router);
let router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      props: true,
      component: {
        render(c) {
          return c("router-view");
        },
      },
      meta: {
        requiresAuth: false,
      },
      children: [
        {
          path: "",
          name: "Home",
          meta: {
            requiresAuth: false,
          },
          component: () => import("./components/Common/appHome"),
        },
        {
          path: "Login",
          name: "Login",
          props: true,
          component: () => import("./components/Common/appLogin"),
          meta: {
            requiresAuth: false,
          },
        },
        // {
        // 	path: 'SignUp',
        // 	name: 'SignUp',
        // 	props: true,
        // 	component: () => import('./components/Common/appSignUp'),
        // 	meta: {
        // 		requiresAuth: false,
        // 	}
        // },
        {
          path: "ForgotPassword",
          name: "ForgotPassword",
          props: true,
          component: () => import("./components/Common/appForgotPassword"),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/",
          component: () => import("./components/Common/appDashboard"),
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              path: "Profile",
              name: "Profile",
              component: () => import("./components/Profile/profile"),
              meta: {
                requiresAuth: true,
              },
            },
          ],
        },
      ],
    },
    {
      path: "/Admin",
      component: () => import("./components/Common/appDashboard"),
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "",
          name: "AdminDashboard",
          component: () => import("./components/Admin/Dashboard/dashboard"),
        },

        // {
        //   path: "/add",
        //   name: "adds",
        //   meta: {
        //     requiresAuth: true,
        //   },
        //   component: () => import("./components/Admin/Admins/addMenu"),
        // },

        {
          path: "/addProduct",
          name: "AddProduct",
          component: () => import("./components/Admin/Products/addProduct"),
        },
        {
          path: "/products",
          name: "Products",
          component: () => import("./components/Admin/Products/products"),
        },
        {
          path: "/product/:id",
          name: "Product",
          component: () => import("./components/Admin/Products/product"),
          props: true,
        },
        {
          path: "/editProduct/:id",
          name: "EditProduct",
          component: () => import("./components/Admin/Products/editProduct"),
          props: true,
        },
        
        {
          path: "purchaseReports",
          name: "Purchases",
          component: () =>
            import("./components/Admin/Reports/Products/purchase"),
        },
        {
          path: "Quotations",
          name: "Quotations",
          component: () =>
            import("./components/Admin/Quotes/Quotations"),
        },
        {
          path: "Policies",
          name: "Policies",
          component: () =>
            import("./components/Admin/Policies/policies"),
        },
      ],
    },

    {
      path: "/ServerError",
      name: "ServerError",
      props: true,
      component: () => import("./components/Common/500"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "*",
      name: "404PageNotFound",
      props: true,
      component: () => import("./components/Common/404"),
      meta: {
        requiresAuth: false,
      },
    },
  ],
  scrollBehavior() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  },
});
router.beforeEach((to, from, next) => {
  if (
    to.matched.some((route) => route.meta.requiresAuth == true) &&
    store.state.isLoggedIn == false
  ) {
    console.log(from);
    next({ name: "Login", params: { lastPage: from } });
    return;
  }
  if (store.state.isLoggedIn == true) {
    axios({
      method: "POST",
      url: "/validate/token",
      headers: {
        token: localStorage.getItem("token"),
      },
    })
      .then((response) => {
        if (!response.data.status) {
          store.commit("sessionOut", true);
          return;
        }
      })
      .catch((err) => {
        var msg = err;
        console.log(msg);
      });
  }
  if (
    (to.name == "Login" || to.name == "Home") &&
    store.state.isLoggedIn == true
  ) {
    next({ name: "AdminDashboard" });
    return;
  }
  next();
});

export default router;
